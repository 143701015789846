<template>
  <div class="user-management-container">
    <el-form ref="form" label-width="200px" :model="form">
      <el-form-item label="个人承诺书标题" prop="contentName;">
        <el-input v-model="form.contentName" />
      </el-form-item>
      <el-form-item label="个人承诺书" prop="content">
        <!-- <fuwenben v-model="form.content" /> -->
        <VueUeditorWrap v-model="form.content" :config="editorConfig" />
      </el-form-item>
    </el-form>
    <el-button style="width: 100%" size="medium" type="primary" @click="save">
      保存
    </el-button>
  </div>
</template>
      
      <script>
import { getList, doEdit, save } from "@/api/xieyi";

import fuwenben from "../vab/editortwo/wang";
import VueUeditorWrap from "vue-ueditor-wrap";
// import Edit from './components/UserManagementEdit'

export default {
  name: "UserManagement",
  components: {
    fuwenben,
    VueUeditorWrap,
  },
  data() {
    return {
      editorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        zIndex: 5000,
        // 上传文件接口, 报错属于正常，若需要验证可使用(也是盗大神的)http://35.201.165.105:8000/controller.php
        // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
        // serverUrl: '/static/UEditor/php/controller.php',
        UEDITOR_HOME_URL: "/UEditor/",
        maximumWords: 999999,
      },
      form: {
        type: 1,
      },
      list: [],
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectRows: "",
      queryForm: {
        type: 1,
        contentName: "",
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const { msg } = await doEdit(this.form);
          this.$alert(msg, {
            dangerouslyUseHTMLString: true,
            type: "success",
          });
        }
      });
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs["edit"].showEdit(row);
      } else {
        this.$refs["edit"].showEdit();
      }
    },
    // handleDelete(row) {
    //   if (row.id) {
    //     this.$baseConfirm('你确定要删除当前项吗', null, async () => {
    //       const { msg } = await doDelete({ id: row.id })
    //       this.$baseMessage(msg, 'success', 'vab-hey-message-success')
    //       await this.fetchData()
    //     })
    //   } else {
    //     if (this.selectRows.length > 0) {
    //       const id = this.selectRows.map((item) => item.id).join()
    //       this.$baseConfirm('你确定要删除选中项吗', null, async () => {
    //         const { msg } = await doDelete({ id })
    //         this.$baseMessage(msg, 'success', 'vab-hey-message-success')
    //         await this.fetchData()
    //       })
    //     } else {
    //       this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
    //     }
    //   }
    // },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    queryData() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },

    async fetchData() {
      this.listLoading = true;
      const res = await getList(this.queryForm);
      console.log(res.data);
      if (res.data != []) {
        this.form = res.data[0];
      }

      this.listLoading = false;
    },
  },
};
</script>
      <style>
</style>